exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-loader__loader--3N_B4 {\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  animation: src-components-loader__spin--3W2vI 2s linear infinite;\n  border: 2px solid #474747;\n  border-top: 2px solid white;\n  display: flex;\n  margin: 20px auto;\n}\n\n@keyframes src-components-loader__spin--3W2vI {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// exports
exports.locals = {
	"loader": "src-components-loader__loader--3N_B4",
	"spin": "src-components-loader__spin--3W2vI"
};