exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Nunito:400,600&display=swap);", ""]);

// module
exports.push([module.id, ".src-components-next-track__articleCard--2FCAx {\n  display: flex;\n  align-items: center;\n  padding: 15px;\n  background: white;\n  border-radius: 4px;\n  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);\n  font-family: Nunito, sans-serif;\n}\n\n.src-components-next-track__title-block--1f5Vw {\n  margin-top: auto;\n  overflow: hidden;\n}\n\n.src-components-next-track__title-block--1f5Vw > div {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  line-height: 19px;\n}\n\n.src-components-next-track__image--3W3mV,\n.src-components-next-track__image--3W3mV svg {\n  height: 45px;\n  width: 45px;\n  margin-right: 15px;\n}\n\n.src-components-next-track__infos--2Lv0T {\n  display: flex;\n  min-width: 0;\n  margin-right: 10px;\n}\n\n.src-components-next-track__title--2VAV0 {\n  color: #474747;\n  font-weight: 600;\n  font-size: 14px;\n}\n\n.src-components-next-track__artist--8YnUa {\n  color: #acacac;\n  font-size: 14px;\n  margin-top: 7px;\n}\n\n.src-components-next-track__buy--16Mrw {\n  display: flex;\n  margin-left: auto;\n  align-self: center;\n  white-space: nowrap;\n}\n\n.src-components-next-track__buy--16Mrw a {\n  padding: 0 12px;\n  margin-left: auto;\n  background-color: white;\n  border: 1px solid #d9d9d9;\n  border-radius: 4px;\n  box-shadow: 0 2px 4px 0 rgba(211, 211, 211, 0.5);\n  color: #474747;\n  text-decoration: none;\n  font-size: 14px;\n  box-sizing: content-box;\n  height: 33px;\n  display: flex;\n  align-items: center;\n}\n\n.src-components-next-track__icon-button--bJC6K {\n  margin-right: 5px;\n}\n\n.src-components-next-track__icon-button--bJC6K svg {\n  width: 13px;\n}\n", ""]);

// exports
exports.locals = {
	"articleCard": "src-components-next-track__articleCard--2FCAx",
	"title-block": "src-components-next-track__title-block--1f5Vw",
	"titleBlock": "src-components-next-track__title-block--1f5Vw",
	"image": "src-components-next-track__image--3W3mV",
	"infos": "src-components-next-track__infos--2Lv0T",
	"title": "src-components-next-track__title--2VAV0",
	"artist": "src-components-next-track__artist--8YnUa",
	"buy": "src-components-next-track__buy--16Mrw",
	"icon-button": "src-components-next-track__icon-button--bJC6K",
	"iconButton": "src-components-next-track__icon-button--bJC6K"
};