import Component from 'lib/component';
import ErrorAlert from 'components/error-alert';
import style from './main.css';
import TrackService, { Track } from '../services/track';
import NextTrack from '../components/next-track';
import Loader from '../components/loader';

export default class Main extends Component {
  public errorAlert = new ErrorAlert();
  public content = new Component();
  public loader = new Loader();
  public nextTrack: Track = {
    artist: '',
    title: '',
    cover: '',
    buyLink: '',
  };

  private displayBuyLink = true;

  public constructor(slug: string, buy: string) {
    super({ className: style.mainView });

    if (!slug) {
      this.setupErrorView();
    } else {
      this.displayBuyLink = buy !== '0';
      setInterval(() => {
        this.fetchNextTrack(slug);
      }, 30000);
      this.fetchNextTrack(slug);
      this.setupViews();
    }
  }

  private setupViews(): void {
    this.errorAlert.hide();
    this.add(this.loader);
    this.add(this.errorAlert);
    this.add(this.content);
  }

  private setupErrorView(): void {
    this.loader.hide();
    this.errorAlert.show();
    this.add(this.errorAlert);
  }

  private fetchNextTrack(id: string): void {
    const trackService = new TrackService();
    const rez = trackService.fetchNextTrack(id);
    rez.subscribe(this.displayNextTrack.bind(this), () =>
      this.setupErrorView(),
    );
  }

  private displayNextTrack(result: Track): void {
    this.loader.hide();
    if (
      this.nextTrack &&
      this.nextTrack.artist === result.artist &&
      this.nextTrack.title === result.title
    ) {
      return;
    }
    this.nextTrack = result;
    this.content.clear();
    this.content.add(new NextTrack(result, this.displayBuyLink));
  }
}
