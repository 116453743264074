import { fromFetch } from 'rxjs/fetch';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { getJson } from '../lib/operators';
import config from '../config';

interface ApiNextTrack {
  title: string;
  artist: string;
  cover_url: string;
  buy_link: string;
}

export interface Track {
  title: string;
  artist: string;
  cover: string;
  buyLink: string;
}

export default class TrackService {
  public url: string;
  public constructor() {
    const { widget } = config.rkApi;
    this.url = `${widget}/radio/`;
  }

  public fetchNextTrack = (idRadio: string): Observable<Track> => {
    const url = `${this.url}${idRadio}/track/next?limit=1`;
    return fromFetch(url).pipe(
      getJson<ApiNextTrack[]>(),
      map(([data]) => {
        // FIXME cover_url et buy_link toujours à null par l'api (https://app.clickup.com/t/n175dg)
        if (!data || !data.title) {
          throw new Error();
        }
        return TrackService.convertToTrack(data);
      }),
      catchError(err => {
        return throwError(err);
      }),
    );
  };

  static convertToTrack(data: ApiNextTrack): Track {
    return {
      title: data.title,
      artist: data.artist,
      cover: data.cover_url,
      buyLink: data.buy_link,
    };
  }
}
