const translations = {
  en: {
    translation: {
      BUY: 'Buy',
    },
  },
  fr: {
    translation: {
      BUY: 'Acheter',
    },
  },
  de: {
    translation: {
      BUY: 'Kaufen',
    },
  },
  es: {
    translation: {
      BUY: 'Comprar',
    },
  },
  it: {
    translation: {
      BUY: 'Acquista',
    },
  },
  pt: {
    translation: {
      BUY: 'Comprar',
    },
  },
};

export { translations as default };
