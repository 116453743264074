import Component from 'lib/component';
import style from './loader.css';

export default class Loader extends Component {
  public constructor() {
    super();
    const html = `<div id="send-loader" class="${style.loader}"></div>`;
    this.updateHtml(html);
  }
}
